
.menu-container{
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    .menu-wrap-top{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            width: 100px;
        }
        h1{
            font-size: 35px;
            color: #FFB03B;
             font-size: 46px;
             font-family: 'Satisfy', sans-serif;
             font-weight: 500;
             margin: 0;
             padding: 0;
        }
        p{
            color:#9699a6;
            font-size:16px;
            font-weight: 500;
            width: 95%;
            text-align: center;
            
        }
    }
    .menu-category-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .category-des{
            width: 90%;
            text-align: center;
            display:block;
            float:left;
            color:#2d2828;
            font-weight: bold;
            font-family: Satisfy,'Open Sans',arial;
        }
       
        .menu-category{
            width: 100vw;
            height: 50px;
            overflow-x: scroll;
            position: relative;
            display: flex;

            .menu-category-wrap{
                // width: 90%;
                position: relative;
    
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
    
                button{
                    padding:10px 20px;
                    margin: 5px;
                    font-size:14px;
                    font-weight: bold;
                    color:#565656;
                    border-radius:0px;
                    border:1px solid #BBBBBB;
                    text-decoration:none;
                    cursor:pointer;
                    text-transform:capitalize;
                    letter-spacing:1px;
                    display: inline-block;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

        }

      
    }

    .menu-item-container{
        width: 100vw;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .menu-item-wrap{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .menu-item-with-img{
                width: 96%;
                padding: 5px;
                margin: 5px;

                display:grid;
                grid-template-columns: 1fr 3fr;

                .menu-item-single-img{
                    width: 120px;
                    height: 120px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    object-fit: contain;
                    border-radius: 10px;
                }
                .menu-item-single-right{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    height: 120px;
                    h2{
                        width: 96%;
                        font-weight: bold;
                        margin: 0;
                        padding: 0;
                        float:left;
                        font-family:Montserrat,arial;
                        text-transform:uppercase;
                        letter-spacing:1px;
                        color:#FFB03B;
                        font-size: 1rem;
                    }

                    div{
                        width: 96%;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        flex-direction: row;
                        p{
                            position: relative;
                            padding: 0;
                            margin: 0;
                            font-weight:600;

                        }
                        .des{
                            display:block;
                            float:left;
                            color:#B1B1B1;
                            font-family: Satisfy,'Open Sans',arial;
                        }
                        button{
                            font-family: 'Satisfy', sans-serif;
                            font-size: 0.8rem;
                            font-weight: bold;
                            padding:10px 20px;
                            font-size:14px;
                            font-weight: bold;
                            color:#565656;
                            border-radius:0px;
                            border:1px solid #BBBBBB;
                            text-decoration:none;
                            cursor:pointer;
                            text-transform:capitalize;
                            letter-spacing:1px;
                            display: inline-block;
                            overflow: hidden;
                            white-space: nowrap;
                            background-color: #F8B03C;
                        }
                    }
                }

            }
            .menu-item-single{
                width: 96%;
                padding: 5px;
                margin: 5px;
                display:grid;
                grid-template-columns: 4fr 1fr;

                .menu-item-single-left{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    h2{
                        width: 96%;
                        font-weight: bold;
                        margin: 0;
                        padding: 0;
                        float:left;
                        font-family:Montserrat,arial;
                        text-transform:uppercase;
                        letter-spacing:1px;
                        color:#FFB03B;
                        font-size: 1rem;
                    }
                    p{
                        width: 96%;
                        float: left;
                        position: relative;
                        padding: 0;
                        margin: 0;
                        font-weight:600;

                    }
                    .des{
                        display:block;
                        float:left;
                        color:#B1B1B1;
                        font-family: Satisfy,'Open Sans',arial;
                    }
                    
                }


                .menu-item-single-btn{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px;

                    button{
                        font-family: 'Satisfy', sans-serif;
                        font-size: 0.8rem;
                        font-weight: bold;
                        padding:10px 20px;
                        font-size:14px;
                        font-weight: bold;
                        color:#565656;
                        border-radius:0px;
                        border:1px solid #BBBBBB;
                        text-decoration:none;
                        cursor:pointer;
                        text-transform:capitalize;
                        letter-spacing:1px;
                        display: inline-block;
                        overflow: hidden;
                        white-space: nowrap;
                        background-color: #F8B03C;  
                    }
                }

            }

        }
    }

    .menu-info{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin: 30px 0px;
        z-index: 0;
        h4{
            font-size: 1.3rem;
            color: #FFB03B;
             font-family: 'Satisfy', sans-serif;
             font-weight: 500;
             margin: 10px 0px;
             padding: 0;
        }
        h5{
            font-size: 1rem;
            color: #FFB03B;
             font-family: 'Satisfy', sans-serif;
             font-weight: 500;
             margin: 5px 0px;
             padding: 0;
        }
        h6{
            font-size: 1rem;
            color: #FFB03B;
             font-family: 'Satisfy', sans-serif;
             font-weight: 500;
             margin: 10px 0px;
             padding: 0;
             a{
                color: #000000;
                font-family: 'Satisfy', sans-serif;
                font-weight: 500;
             }
        }

        iframe{
            border: 1px solid black;
            border-radius: 15px;
        }

    }

    .cart-btn{
        position:absolute;
        top: 80vh;
        right: 5vw;
        background-color: #FFB03B;
        border-radius: 50%;
        padding: 5px;
        z-index: 1000;
        box-shadow: -1px -1px 34px 3px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px -1px 34px 3px rgba(0,0,0,0.75);
-moz-box-shadow: -1px -1px 34px 3px rgba(0,0,0,0.75);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            width: 50px;
        }
        div{
            position: relative;
            .pop-n{
                position: absolute;
    top: -22px;
    right: -37px;
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
    border-radius: 50%;
    background-color: #0b9d8a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
            }
        }
       
    }
    .cart-click-container{
        position: absolute;
        height: 40vh;
        background-color: rgba(86, 186, 176, 0);
        width: 100vw;
    }

    .cart-container{
        position: absolute;
        height: 60vh;
        background-color: rgb(76, 76, 76);
        width: 100vw;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        overflow-y: scroll;
        .menu-item-wrap{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .menu-item-with-img{
                width: 96%;
                padding: 5px;
                margin: 5px;

                display:grid;
                grid-template-columns: 1fr 3fr;

                .menu-item-single-img{
                    width: 120px;
                    height: 120px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    object-fit: contain;
                    border-radius: 10px;
                }
                .menu-item-single-right{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    height: 120px;
                    h2{
                        width: 96%;
                        font-weight: bold;
                        margin: 0;
                        padding: 0;
                        float:left;
                        font-family:Montserrat,arial;
                        text-transform:uppercase;
                        letter-spacing:1px;
                        color:#FFB03B;
                        font-size: 1rem;
                    }

                    div{
                        width: 96%;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        flex-direction: row;
                        p{
                            position: relative;
                            padding: 0;
                            margin: 0;
                            font-weight:600;
                            color: white;
                        }
                        .des{
                            display:block;
                            float:left;
                            color:#B1B1B1;
                            font-family: Satisfy,'Open Sans',arial;
                        }
                        button{
                            font-family: 'Satisfy', sans-serif;
                            font-size: 0.8rem;
                            font-weight: bold;
                            // padding:10px 20px;
                            font-size:14px;
                            font-weight: bold;
                            color:#565656;
                            border-radius:0px;
                            // border:1px solid #BBBBBB;
                            text-decoration:none;
                            cursor:pointer;
                            text-transform:capitalize;
                            letter-spacing:1px;
                            display: inline-block;
                            overflow: hidden;
                            white-space: nowrap;
                            background-color: #f8b03c00;
                            img{
                                width: 30px;
                            }
                        }
                    }
                }

            }
            .menu-item-single{
                width: 96%;
                padding: 5px;
                margin: 5px;
                display:grid;
                grid-template-columns: 4fr 1fr;

                .menu-item-single-left{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    h2{
                        width: 96%;
                        font-weight: bold;
                        margin: 0;
                        padding: 0;
                        float:left;
                        font-family:Montserrat,arial;
                        text-transform:uppercase;
                        letter-spacing:1px;
                        color:#FFB03B;
                        font-size: 1rem;
                    }
                    p{
                        width: 96%;
                        float: left;
                        position: relative;
                        padding: 0;
                        margin: 0;
                        font-weight:600;
                        color: white;

                    }
                    .des{
                        display:block;
                        float:left;
                        color:#B1B1B1;
                        font-family: Satisfy,'Open Sans',arial;
                    }
                    
                }


                .menu-item-single-btn{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin: 5px;

                    button{
                        font-family: 'Satisfy', sans-serif;
                        font-size: 0.8rem;
                        font-weight: bold;
                        // padding:10px 20px;
                        font-size:14px;
                        font-weight: bold;
                        color:#565656;
                        border-radius:0px;
                        // border:1px solid #BBBBBB;
                        text-decoration:none;
                        cursor:pointer;
                        text-transform:capitalize;
                        letter-spacing:1px;
                        display: inline-block;
                        overflow: hidden;
                        white-space: nowrap;
                        background-color: #f8b03c00;
                        img{
                            width: 30px;
                        }
                    }
                }

            }

        }
    }
    .special-wrap{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .special-wrap-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 92%;
            h4{
                width: 95%;
                align-items: flex-start;
                font-family: 'Satisfy', sans-serif;
                            font-size: 1.2rem;
                            font-weight: bolder;
                            color:#FFB03B
            }
            img{
                width: 20px;
            }
        }
     

        .special-items-wrap{
           
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                .menu-item-with-img{
                    width: 96%;
                    padding: 5px;
                    margin: 5px;
    
                    display:grid;
                    grid-template-columns: 1fr 3fr;
    
                    .menu-item-single-img{
                        width: 120px;
                        height: 120px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        object-fit: contain;
                        border-radius: 10px;
                    }
                    .menu-item-single-right{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: column;
                        height: 120px;
                        h2{
                            width: 96%;
                            font-weight: bold;
                            margin: 0;
                            padding: 0;
                            float:left;
                            font-family:Montserrat,arial;
                            text-transform:uppercase;
                            letter-spacing:1px;
                            color:#FFB03B;
                            font-size: 1rem;
                        }
    
                        div{
                            width: 96%;
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;
                            flex-direction: row;
                            p{
                                position: relative;
                                padding: 0;
                                margin: 0;
                                font-weight:600;
    
                            }
                            .ori-price{
                                text-decoration: line-through;
                            }
                            .sale-price{

                            }

                            .des{
                                display:block;
                                float:left;
                                color:#B1B1B1;
                                font-family: Satisfy,'Open Sans',arial;
                            }
                            button{
                                font-family: 'Satisfy', sans-serif;
                                font-size: 0.8rem;
                                font-weight: bold;
                                padding:10px 20px;
                                font-size:14px;
                                font-weight: bold;
                                color:#565656;
                                border-radius:0px;
                                border:1px solid #BBBBBB;
                                text-decoration:none;
                                cursor:pointer;
                                text-transform:capitalize;
                                letter-spacing:1px;
                                display: inline-block;
                                overflow: hidden;
                                white-space: nowrap;
                                background-color: #F8B03C;
                            }
                        }
                    }
    
                }
                .menu-item-single{
                    width: 96%;
                    padding: 5px;
                    margin: 5px;
                    display:grid;
                    grid-template-columns: 4fr 1fr;
    
                    .menu-item-single-left{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: column;
                        h2{
                            width: 96%;
                            font-weight: bold;
                            margin: 0;
                            padding: 0;
                            float:left;
                            font-family:Montserrat,arial;
                            text-transform:uppercase;
                            letter-spacing:1px;
                            color:#FFB03B;
                            font-size: 1rem;
                        }
                        p{
                            width: 96%;
                            float: left;
                            position: relative;
                            padding: 0;
                            margin: 0;
                            font-weight:600;
    
                        }
                        .ori-price{
                            text-decoration: line-through;
                        }
                        .sale-price{
                            color: crimson;
                            font-weight: 700;
                        }
                        .des{
                            display:block;
                            float:left;
                            color:#B1B1B1;
                            font-family: Satisfy,'Open Sans',arial;
                        }
                        
                    }
    
    
                    .menu-item-single-btn{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 5px;
    
                        button{
                            font-family: 'Satisfy', sans-serif;
                            font-size: 0.8rem;
                            font-weight: bold;
                            padding:10px 20px;
                            font-size:14px;
                            font-weight: bold;
                            color:#565656;
                            border-radius:0px;
                            border:1px solid #BBBBBB;
                            text-decoration:none;
                            cursor:pointer;
                            text-transform:capitalize;
                            letter-spacing:1px;
                            display: inline-block;
                            overflow: hidden;
                            white-space: nowrap;
                            background-color: #F8B03C;  
                        }
                    }
    
                }
    
            
        }
    }

}

#loading{
    position: absolute;
    width: 180px;
    top: 40vh;
    left: 30vw;
}