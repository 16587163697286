.user-dashboard{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to Right, #FCE0BC,#F5C8EC);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .user-wrap{
        width: 94vw;
        height: 96vh;
        background: linear-gradient(to Right, #FEF1E1,#FAE6F8);
        border-radius: 40px;
        hr{
            height: 10px;
            background-color: rgb(255, 255, 255);
            border: none;
        }
        .user-wrap-top{
            padding: 20px;
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .user-wrap-top-left{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                width: 100%;
                img{
                    width: 60px;
                }
                h2{
                    :nth-child(1){
                        color: #ea55dd;
                    }
                    :nth-child(2){
                        color: #ea55dd;
                    }
                }

                button{
                    background-color: cornflowerblue;
                    border: none;
                    border-radius: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    width: 70%;
                    font-size: large;
                    font-weight: 400;
                    img{
                        width: 40px;
                        border-radius: 50%;
                        border: 1px solid white;
                        background-color: white;
                    }
                }
            }


        }

        .user-wrap-mid{
            display: grid;
            grid-template-columns: 1fr 7fr;
            grid-column-gap: 30px;
            padding: 20px;

            .user-wrap-mid-left{
                background-color: white;
                width: 100%;
                height: 70vh;
                overflow-y: scroll;

                hr{
                    background-color: #FEF0E1;
                    width: 100%;
                    height: 10px;
                }

                .user-wrap-mid-left-wrap{
                    display: grid;
                    grid-template-columns: repeat(1fr);
                    grid-row-gap: 20px;
                    button{
                        width: 90%;
                        background-color: #FBD5CE;
                        border: none;
                        font-size: 1rem;
                        font-weight: bold;
                        padding: 10px;
                        border-bottom-right-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }

            }

            .user-wrap-mid-right{
                background-color: white;
                width: 100%;
                // display: flex;
                justify-content: center;
                align-items: center;
                height: 73vh;
                overflow-y: scroll;

                .dashboard-container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    iframe{
                        width: 100%;
                        border-radius: 20px;
                        border:1px solid black;
                        height: 250px;
                    }

                    .dashboard-wrap{
                        display: grid;
                        grid-template-columns: repeat(3,1fr);
                        grid-column-gap: 30px;
                        width: 100%;
                        padding: 30px;
                        div{
                            width: 80%;
                            border-radius: 20px;
                            background:linear-gradient(to Right, #c8f1c7,#a7f099);
                            display: flex;
                            justify-content: center;
                            align-items:center;
                            flex-direction: column;
                            padding: 10px;
                            img{
                                width: 60px;
                            }
                        }
                    }
                }

                .category-container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 96%;

                    .category-container-wrap{
                        display: grid;
                        grid-template-columns: repeat(4,1fr);
                        grid-column-gap: 30px;
                        width: 100%;
                        padding: 10px;

                        .category-container-wrap-grid{
                            width: 70%;
                            border-radius: 20px;
                            background:linear-gradient(to Right, #c8f1c7,#a7f099);
                            display: flex;
                            justify-content: center;
                            align-items:center;
                            flex-direction: column;
                            margin: 10px;
                            padding: 10px;
                            img{
                                width: 40px;
                            }
                            h6{
                                font-size: 1rem;
                                font-weight: bold
                            }
                            div{
                                width: 100%;
                                display: flex;
                                justify-content:space-between;
                                align-items: center;
                            }
                        }

                    }

                }
                .order-container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 96%;

                    .order-container-wrap{
                        display: grid;
                        grid-template-columns: repeat(5,1fr);
                        grid-column-gap: 30px;
                        width: 100%;
                        padding: 10px;
                        .category-container-wrap-grid{
                            width: 70%;
                            border-radius: 20px;
                            background:linear-gradient(to Right, #ebc7f1,#e4aaf3);
                            display: flex;
                            justify-content: center;
                            align-items:center;
                            flex-direction: column;
                            margin: 10px;
                            padding: 10px;
                            img{
                                width: 40px;
                            }
                            h6{
                                font-size: 1rem;
                                font-weight: bold
                            }
                            div{
                                width: 100%;
                                display: flex;
                                justify-content:space-between;
                                align-items: center;
                            }
                        }

                    }



                }
                .item-container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 96%;
                    .category-container-wrap{
                        display: grid;
                        grid-template-columns: repeat(4,1fr);
                        grid-column-gap: 30px;
                        width: 100%;
                        padding: 10px;

                        .category-container-wrap-grid{
                            width: 70%;
                            border-radius: 20px;
                            background:linear-gradient(to Right, #c8f1c7,#a7f099);
                            display: flex;
                            justify-content: center;
                            align-items:center;
                            flex-direction: column;
                            margin: 10px;
                            padding: 10px;
                            img{
                                width: 40px;
                            }
                            h6{
                                font-size: 1rem;
                                font-weight: bold
                            }
                            div{
                                width: 100%;
                                display: flex;
                                justify-content:space-between;
                                align-items: center;
                            }
                        }

                    }
                }
                .special-container{
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;
                    width: 96%;
                  .special-wrap{
                    width: 100%;
                    padding: 10px;
                    height: 70vh;
                    overflow-y:scroll;
                    .special-wrap-day{
                        width: 100%;
                        .special-wrap-day-items{
                            width: 100%;
                            overflow-x: scroll;
                            
                            .special-wrap-day-items-scroll{
                                width: fit-content;

                                display: flex;
                                justify-content:flex-start; 
                                align-items: center;
                                flex-direction: row;
                                overflow-x: scroll;
                                div{
                                    margin: 10px;
                                     img{
                                         width: 20px;
                                         position: absolute;right: 0;top: 0;
                                     }
                                     width: 250px;
                                     height: 100px;
                                     border-radius: 10px;
                                     background-color: #4489ea;
                                     position: relative;
                                     display: flex;
                                     justify-content: center;
                                     align-items: center;
                                     flex-direction: column;
                                     h6{
                                         padding:0;
                                         margin: 0;
                                     }
                                     p{
                                         padding:0;
                                         margin: 0; 
                                     }
                                 }
                            }
                            button{
                                padding: 30px;
                                background:linear-gradient(to Right, #c8f1c7,#a7f099);
                                font-size: 1rem;
                                font-weight: bold
                            }
                           
                        }
                    }
                  }
                }

            }

        }



    }

    .user-profile-wrap{
        width: 84vw;
        height: 80vh;
        overflow-y: scroll;

        background: linear-gradient(to Right, #f6f7d3,#d8f4d3);
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        #close-btn{
            position: absolute;
            width: 50px;
            right: 10px;
            top: 10px;
        }

        .user-profile-wrap-info{
            height: 70vh;
            #user-profile-btn{
                position: relative;
                margin: 20px 150px;
                width: 20%;
                padding: 5px;
                background-color: #4489ea;
                color: #fff;
                font-size: large;
                border-radius: 10px;
                border:none;
            }

            .group2{
                position: relative;
            }

            .group { 
                position:relative; 
                margin-bottom:25px; 
              }
              input {
                font-size:18px;
                padding:10px 10px 10px 5px;
                display:block;
                width:600px;
                border:none;
                border-bottom:1px solid #757575;
              }
              input:focus { outline:none; }
           
              label {
                color:#999; 
                font-size:18px;
                font-weight:normal;
                position:absolute;
                pointer-events:none;
                left:5px;
                top:10px;
                transition:0.2s ease all; 
                -moz-transition:0.2s ease all; 
                -webkit-transition:0.2s ease all;
              }
              
              /* active state */
              input:focus ~ label, input:valid ~ label 		{
                top:-20px;
                font-size:14px;
                color:#5264AE;
              }
              
              /* BOTTOM BARS ================================= */
              .bar 	{ position:relative; display:block; width:600px; }
              .bar:before, .bar:after 	{
                content:'';
                height:2px; 
                width:0;
                bottom:1px; 
                position:absolute;
                background:#5264AE; 
                transition:0.2s ease all; 
                -moz-transition:0.2s ease all; 
                -webkit-transition:0.2s ease all;
              }
              .bar:before {
                left:50%;
              }
              .bar:after {
                right:50%; 
              }
              
              /* active state */
              input:focus ~ .bar:before, input:focus ~ .bar:after {
                width:50%;
              }
              
              /* HIGHLIGHTER ================================== */
              .highlight {
                position:absolute;
                height:60%; 
                width:100px; 
                top:25%; 
                left:0;
                pointer-events:none;
                opacity:0.5;
              }
              
              /* active state */
              input:focus ~ .highlight {
                -webkit-animation:inputHighlighter 0.3s ease;
                -moz-animation:inputHighlighter 0.3s ease;
                animation:inputHighlighter 0.3s ease;
              }
              
              /* ANIMATIONS ================ */
              @-webkit-keyframes inputHighlighter {
                  from { background:#5264AE; }
                to 	{ width:0; background:transparent; }
              }
              @-moz-keyframes inputHighlighter {
                  from { background:#5264AE; }
                to 	{ width:0; background:transparent; }
              }
              @keyframes inputHighlighter {
                  from { background:#5264AE; }
                to 	{ width:0; background:transparent; }
              }
            
        }
    }

    .category-create-wrap{
        width: 84vw;
        height: 50vh;
        overflow-y: scroll;

        background: linear-gradient(to Right, #f6f7d3,#d8f4d3);
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        #close-btn{
            position: absolute;
            width: 50px;
            right: 10px;
            top: 10px;
        }

        .user-profile-wrap-info{
            height: 50vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            #user-profile-btn{
                position: relative;
                margin: 20px 50px;
                width: 20%;
                padding: 5px;
                background-color: #4489ea;
                color: #fff;
                font-size: large;
                border-radius: 10px;
                border:none;
            }

            .group2{
                position: relative;
            }

            .group { 
                position:relative; 
                margin-bottom:25px; 
              }
              input {
                font-size:18px;
                padding:10px 10px 10px 5px;
                display:block;
                width:600px;
                border:none;
                border-bottom:1px solid #757575;
              }
              input:focus { outline:none; }
           
              label {
                color:#999; 
                font-size:18px;
                font-weight:normal;
                position:absolute;
                pointer-events:none;
                left:5px;
                top:10px;
                transition:0.2s ease all; 
                -moz-transition:0.2s ease all; 
                -webkit-transition:0.2s ease all;
              }
              
              /* active state */
              input:focus ~ label, input:valid ~ label 		{
                top:-20px;
                font-size:14px;
                color:#5264AE;
              }
              
              /* BOTTOM BARS ================================= */
              .bar 	{ position:relative; display:block; width:600px; }
              .bar:before, .bar:after 	{
                content:'';
                height:2px; 
                width:0;
                bottom:1px; 
                position:absolute;
                background:#5264AE; 
                transition:0.2s ease all; 
                -moz-transition:0.2s ease all; 
                -webkit-transition:0.2s ease all;
              }
              .bar:before {
                left:50%;
              }
              .bar:after {
                right:50%; 
              }
              
              /* active state */
              input:focus ~ .bar:before, input:focus ~ .bar:after {
                width:50%;
              }
              
              /* HIGHLIGHTER ================================== */
              .highlight {
                position:absolute;
                height:60%; 
                width:100px; 
                top:25%; 
                left:0;
                pointer-events:none;
                opacity:0.5;
              }
              
              /* active state */
              input:focus ~ .highlight {
                -webkit-animation:inputHighlighter 0.3s ease;
                -moz-animation:inputHighlighter 0.3s ease;
                animation:inputHighlighter 0.3s ease;
              }
              
              /* ANIMATIONS ================ */
              @-webkit-keyframes inputHighlighter {
                  from { background:#5264AE; }
                to 	{ width:0; background:transparent; }
              }
              @-moz-keyframes inputHighlighter {
                  from { background:#5264AE; }
                to 	{ width:0; background:transparent; }
              }
              @keyframes inputHighlighter {
                  from { background:#5264AE; }
                to 	{ width:0; background:transparent; }
              }
            
        }

        .delete-category-wrap{
            width: 80%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            :nth-child(1){
                background-color: crimson;
            }
            button{
                width: 100px;padding: 20px;
                font-size: 1rem;
                font-weight: bold;
                border-radius: 20px;
            }
        }

    }

}