.auth-login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height:100vh;
    background-color: #fff;
    border-radius: 10px;
    background-image: url(https://res.cloudinary.com/dvr397xda/image/upload/v1676934491/ezgif.com-optimize_gau27z.gif);
    background-size: cover;
    background-position: center;

    .auth-login-wrap{

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 80vw;
        background-color: rgba(255, 255, 255, 0.603);
        padding: 1vw;
        border-radius: 15px;
            h2{
                color: rgb(36, 17, 17);
            }

            div{
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                label{
                    height: 3vh;
                    font-size: 1.5em;
                }
                input{
                    height: 3vh;
                    width: 30vw;
                    margin-top: 0px;
                }
                padding-top: 20px;
            }

            button{
                width: 20vw;
                margin-top: 30px;
            }
         
        
    }

}