.preview-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 5px;

    img{

        width: 300px;
        border: 1px solid black;
        border-radius: 15px;
    }
}
.admin-container{
    .admin-wrap{
        .admin-wrap-users{
            table{
                thead{
                    tr{
                        :nth-child(1){
                            width: 200px;
                        }
                        :nth-child(2){
                            width: 200px;
                        }
                       
                    }
                   
                }
                td{
                    img{
                        width: 250px;
                    }
                }
            }
        }
    }
}
