.admin-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    button{
        background-color: rgb(40, 85, 232);
        padding: 5px 10px;
        border-radius: 5px;
        border: none;
        color: white;
    }

    .admin-wrap{

        .admin-wrap-users{
            height: 70vh;
            width: 95vw;
            overflow-y: scroll;
            table{
                margin-top: 20px;
                width: 85vw;
                color: black;
                img{
                    width: 80px;
                }
            }
            table, tr,th, td {
              border:1px solid black;
              padding: 2px;
            }
            td:nth-child(2){
               width: auto;
            }
            td{
                padding: 0x;
                align-items: center;
                text-align: center;
                img{
                    width: 60%;
                }
            }
            p{
                color:black;
                font-weight: 600;
                font-size: 1.3em;
            }
        }

        .admin-wrap-top{

        }
        .pagenation-wrap{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
            width: 98vw;
            height: 3vh;
            margin-top: 5px;
            
            div{
                display: flex;
                justify-content:center;
                align-items:center;
                p{
                    color: black;
                    font-size: large;
                }
            }
        }
    }

    .admin-create{
        width: 90vw;
        height: 80vh;
        background-color: aliceblue;
        border-radius: 20px;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .admin-create-item{
            padding: 20px;
        }
        #close-icon{
            position: absolute;
            top: 2vh;
            right: 2vw;
            width: 50px;
        }
    }
}